export const constants = {
  name: 'Jimmy Hurtado',
  avatar: 'https://avatars.githubusercontent.com/u/35477201?v=4',
  profilesUrls: {
    linkedin: 'https://www.linkedin.com/in/jhm-film-maker-18a16828b/',
    github: 'https://github.com/jimmyhurta2',
    email: 'jimmy60908135@gmail.com',
    cv: '/04/03/2022.pdf',
    twitter: 'https://twitter.com/Jhmr19',
    number: '+591 60952567',
    whatsapp: 'https://wa.me/59160952567',
    instagram: 'https://www.instagram.com/jimmythewonderfull/',
    facebook: 'https://www.facebook.com/jimmy.hurtado.the.wonderfull',
    buymeacoffee: '',
  },
  icons: {
    linkedin: 'https://i.imgur.com/z5abyyx.png',
    github: 'https://i.imgur.com/IDzgJgj.png',
    email: 'https://i.imgur.com/T7Zqh03.png',
    twitter: 'https://i.imgur.com/XOaGLwf.png',
  },
  cursos: {
    inglesB1:
      'https://i.pinimg.com/originals/e1/94/d7/e194d7871328f8ab53ae9ed2a2c30b65.jpg',
    inglesC1:
      'https://i.pinimg.com/originals/a4/b1/81/a4b181ce11e9197bf214d134deca5364.jpg',
    ingSoftware:
      'https://i.pinimg.com/originals/9a/03/be/9a03be94d4333fb4c6ddc565c303f3dc.jpg',
    frontendDeveloper:
      'https://platzi.com/p/AntonioAyola/curso/2467-frontend-developer/diploma/detalle/',
    scrumStudy:
      'https://i.pinimg.com/originals/23/51/62/235162754a3e8464f363c905abd7a1e6.jpg',
    scrum:
      'https://www.linkedin.com/learning/certificates/5a305bf06cee89fba7ca237afc285ba9138b7a4fcbfee0145d6b4e0bdaf41f7e',
    ingDeSoftware:
      'https://platzi.com/p/AntonioAyola/curso/1098-ingenieria/diploma/detalle/',
    algoritmosPensamientoLogico:
      'https://platzi.com/p/AntonioAyola/curso/2218-pensamiento-logico/diploma/detalle/',
    computacionBasica:
      'https://platzi.com/p/AntonioAyola/curso/1741-computacion-basica-2019/diploma/detalle/',
    introduccionAPIRest: 'https://ed.team/@antonioayola/curso/api-rest',
    git: 'https://www.udemy.com/certificate/UC-0c57c4a0-ee62-47a0-a349-b8feccc4dcc6/',
    noCountry:
      'https://i.pinimg.com/originals/5b/e8/f0/5be8f0bcd288379face4eb2bab6b69c6.jpg',
  },
  language: 'es_mx',
  siteData: {
    siteUrl: 'https://jimmyhurtado.online',
    siteRepo: 'https://github.com/jimmyhurta2',
  },
};
export default constants;
