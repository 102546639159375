const data = [
  {
    id: 1,
    image:
      'https://i.pinimg.com/originals/9f/ca/f5/9fcaf56d43a6ff066eaf8f71f5b0158f.png',
    title: 'Creatividad',
    /*icon: 'fas fa-mobile-alt main',*/

    description:
      'En mi trabajo, la creatividad es clave. Constantemente tengo que idear conceptos visuales y auditivos únicos que atraigan la atención del espectador.',
  },
  {
    id: 2,
    image:
      'https://i.pinimg.com/originals/83/62/65/8362654edad80f77c6c5752ecb3a3d68.png',
    /*icon: 'fas fa-code main',*/
    title: 'Narrativa visual',
    description:
      'Comprender cómo contar una historia a través de elementos visuales y auditivos es fundamental para mí. Esto implica planificar la estructura de mis proyectos y la secuencia de escenas.',
  },
  {
    id: 3,
    image:
      'https://i.pinimg.com/originals/71/19/52/7119527a411cd30f175a13e6e08fe6e9.png',
    /* icon: 'fas fa-globe main',*/
    title: 'Ética profesional',
    description:
      'Sigo estándares éticos y legales al utilizar contenido con derechos de autor y al trabajar en proyectos comerciales.',
  },
  /* {
    id: 4,
    image: 'https://picsum.photos/50/50?random=3',
    icon: 'fas fa-globe main',
    title: 'Mejor Diseño Mejor Experiencia',
    description: 'Muestra diseños innovadores y experiencia de usuario únicos',
  },
  {
    id: 5,
    image: 'https://picsum.photos/50/50?random=3',
    icon: 'fas fa-globe main',
    title: 'Patrones',
    description:
      'Estudio de diseño de patrones para resolución persistentes en el desarrollo web',
  },
  {
    id: 6,
    image: 'https://picsum.photos/50/50?random=3',
    icon: 'fas fa-globe main',
    title: 'Web App',
    description:
      'El uso de nuevas tecnologías para la resolución de problemas en aplicaciones web',
  }, */
];

export default data;
